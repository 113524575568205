/*
@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.wbdvmtool__youranswers {
  display: none;
}

.wbdvmtool__questionsgroup__title {
  display: none;
}

.aowleeftijd {
  font-weight: bold;
}

.wbdvmtool__groups,
.wbdvmtool__results__email,
.wbdvmtool__givenanswers,
.wbdvmtool__results__shareoptions {
  margin-top: 25px;
}

@media (max-width: 750px) {
  .wbdvmtool__groups,
  .wbdvmtool__results__email,
  .wbdvmtool__givenanswers,
  .wbdvmtool__results__shareoptions {
    margin-top: 20px;
  }
}
.wbdvmtool__results--hasresults .wbdvmtool__results__noresults {
  display: none;
}

.wbdvmtool__results--noresults .wbdvmtool__results__header, .wbdvmtool__results--noresults .wbdvmtool__results__email {
  display: none;
}

@media (max-width: 750px) {
  .wbdvmtool__sendresultslink {
    margin-top: 30px;
  }
}
@media (min-width: 751px) {
  .wbdvmtool__sendresultslink {
    margin-top: 32px;
  }
}
div.wbdvmtool__group { /* Firefox */
  /* Chrome */
  counter-reset: count;
}
div.wbdvmtool__group details summary {
  cursor: pointer;
}
div.wbdvmtool__group details summary {
  list-style-type: none;
}
div.wbdvmtool__group details summary::-webkit-details-marker {
  display: none;
}
div.wbdvmtool__group details summary::marker {
  display: none;
}

.wbdvmtool__groups {
  counter-reset: count;
}

details.wbdvmtool__group { /* Firefox */
  /* Chrome */
  border-top: 1px solid var(--tool-effectgroup-divider-color);
}
details.wbdvmtool__group > summary {
  cursor: pointer;
}
details.wbdvmtool__group > summary {
  list-style-type: none;
}
details.wbdvmtool__group > summary::-webkit-details-marker {
  display: none;
}
details.wbdvmtool__group > summary::marker {
  display: none;
}

details.wbdvmtool__group:last-child {
  border-bottom: 1px solid var(--tool-effectgroup-divider-color);
}

div.wbdvmtool__group::after {
  display: block;
  content: "";
  margin-top: 24px;
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  border-bottom: 1px solid var(--color-green);
}

.wbdvmtool__group__header {
  display: flex;
}

.wbdvmtool__group__icon {
  flex: none;
  width: var(--tool-effectgroup-icon-size);
  margin-right: var(--tool-effectgroup-icon-margin);
}

.wbdvmtool__group__title {
  flex: 1 1 0px;
}

@media (max-width: 800px) {
  .wbdvmtool__group__title {
    padding-top: 10px;
  }
}
.wbdvmtool__effect summary > h2,
.wbdvmtool__group summary > .wbdvmtool__group__title {
  display: flex;
  color: var(--tool-effectgroup-title-color);
  font: var(--tool-effectgroup-title-font);
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.wbdvmtool__effect summary > h2 {
  justify-content: space-between;
}

.wbdvmtool__group summary:hover {
  background-color: var(--tool-effectgroup-header-hover-color);
}

.wbdvmtool__groups summary > h2 > .h2text {
  flex: 1 0 0px;
}

.wbdvmtool__groups summary .wbdvmtool__group__title::after {
  color: var(--tool-themecolor-secondary);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f078";
  font-size: 18px;
  margin-left: auto;
  padding-left: 20px;
  margin-right: 20px;
}

.wbdvmtool__group__header {
  padding: 0;
}

.wbdvmtool__group__title {
  padding-top: 16px;
}

.wbdvmtool__group__header--withicon .wbdvmtool__group__title {
  display: block;
  min-height: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.wbdvmtool__groups details[open] summary > h2::after,
details.wbdvmtool__group[open] summary > .wbdvmtool__group__title::after {
  content: "\f077";
}

.wbdvmtool__effect__description {
  padding-bottom: 30px;
}

.wbdvmtool__effect__description > p.normal,
.wbdvmtool__effect__description > ol.ordered,
.wbdvmtool__effect__description > ul.unordered {
  font: var(--tool-effectgroup-text-font);
  color: var(--tool-effectgroup-text-color);
  padding: 0;
}

.wbdvmtool__effect__description > *:first-child {
  margin-top: 0;
}

.wbdvmtool__effect__description > *:last-child {
  margin-bottom: 0;
}

@media (max-width: 750px) {
  .wbdvmtool__group__title {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .wbdvmtool__groups summary .wbdvmtool__group__title::after,
  .wbdvmtool__groups details[open] summary > h2::after,
  details.wbdvmtool__group[open] summary > .wbdvmtool__group__title::after {
    margin-right: 5px;
  }
}
@media (min-width: 751px) {
  .wbdvmtool__effect__description > * {
    margin-left: calc(var(--tool-effectgroup-icon-size) + var(--tool-effectgroup-icon-margin));
  }
}
.wbdvmtool__groups details > *:first-child + * {
  margin-top: 10px;
}

.wbdvmtool__results__email {
  background-color: var(--tool-panels-background);
  border-radius: 8px;
  padding: var(--tool-results-panel-padding-v) var(--tool-results-panel-padding-h);
}

.wbdvmtool__results__email__form__emailfieldholder {
  margin-top: 10px;
  position: relative;
  width: 100%;
  max-width: 341px;
  display: flex;
  align-items: center;
}

.wbdvmtool__results__email__form__intro > .heading {
  margin-bottom: 10px;
}

.wbdvmtool__results__email__form .heading::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0e0";
  font-size: 22px;
  margin-right: 11px;
}

.wbdvmtool__results__email__form__intro p {
  font: var(--tool-emailresultlink-text-font);
  color: #333333;
}

.wbdvmtool__results__email__form input[name=email] {
  width: 100%;
  border: 1px solid #777777;
  font-size: 16px;
}

.wbdvmtool__results__email__form button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  padding: 4px 6px;
}

.wbdvmtool__results__email__form button::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 18px;
  color: var(--tool-themecolor);
}

.wbdvmtool__results__email__success {
  display: none;
}

.wbdvmtool__results__email--sent .wbdvmtool__results__email__form {
  display: none;
}
.wbdvmtool__results__email--sent .wbdvmtool__results__email__success {
  display: block;
}

.wbdvmtool__givenanswers {
  display: block;
  border: 1px solid var(--tool-panels-border-color);
  border-radius: 8px;
  background-color: var(--tool-panels-background);
  padding: var(--tool-results-panel-padding-v) var(--tool-results-panel-padding-h);
}

details.wbdvmtool__givenanswers { /* Firefox */
  /* Chrome */
}
details.wbdvmtool__givenanswers summary {
  cursor: pointer;
}
details.wbdvmtool__givenanswers summary {
  list-style-type: none;
}
details.wbdvmtool__givenanswers summary::-webkit-details-marker {
  display: none;
}
details.wbdvmtool__givenanswers summary::marker {
  display: none;
}

.wbdvmtool__givenanswers__heading.wbdvmtool__givenanswers__heading {
  color: var(--tool-heading-color);
  font: var(--tool-results-h2);
  display: flex;
  justify-content: space-between;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.wbdvmtool__givenanswers__heading::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f06e";
  font-size: 22px;
  margin-right: 12px;
}

.wbdvmtool__givenanswers__heading::after {
  margin-left: auto;
  padding-left: 20px;
  color: var(--tool-themecolor-secondary);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f078";
  font-size: 18px;
}

.wbdvmtool__givenanswers__intro {
  margin-top: 20px;
  font: 16px/23px Arial;
}

.wbdvmtool__givenanswers__items {
  margin-top: 30px;
}

.wbdvmtool__givenanswer {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: min-content 1fr;
}

.wbdvmtool__givenanswer + .wbdvmtool__givenanswer {
  margin-top: 20px;
}

.wbdvmtool__givenanswer::before {
  grid-column: 1;
  grid-row: 1;
  align-self: baseline;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  color: var(--color-green);
  font-size: 16px;
  margin-right: 14px;
}

.wbdvmtool__givenanswer__question {
  grid-column: 2;
  grid-row: 1;
  align-self: baseline;
  font: bold 16px/23px Arial;
}

.wbdvmtool__givenanswer__answer {
  grid-column: 2;
  grid-row: 2;
  font: 16px/23px Arial;
}

.wbdvmtool__givenanswers__restarttool {
  margin-top: 30px;
  cursor: pointer;
}

.wbdvmtool__results__sharetool__intro {
  margin-bottom: 20px;
}