@charset "UTF-8";
.sharecomponent__buttonbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 18px;
}

.sharecomponent__buttonbar .wh-share {
  appearance: none;
  border: 0;
  margin: 0;
  background: none;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: 1px solid var(--component-sharepage-button-color);
  border-radius: 8px;
  cursor: pointer;
}

.sharecomponent__buttonbar > .wh-share + .wh-share {
  margin-left: var(--component-sharepage-button-spacing, 13px);
}

.sharecomponent__buttonbar .wh-share:hover {
  background-color: var(--component-sharepage-button-color);
  background-image: none;
  color: #FFFFFF;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share[data-network=email]::before,
.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}

.sharecomponent__buttonbar .wh-share.wh-share::before {
  font-size: 20px;
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}