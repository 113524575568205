/*

This file must contain styling which is ONLY USED for iframe EMBEDDING.

*/
/* Global page, HTML5 setup, style reset */
html, body, p, h1, h2, h3, h4, h5, h6, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

html, body {
  background-color: #ffffff;
}

body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

* {
  box-sizing: border-box;
}

html,
body {
  font: 14px/100% Arial, Sans-Serif;
  color: #000000;
}

.wbdvmtool {
  max-width: 750px;
}

.wbdvmtool.wbdvmtool .date-day, .wbdvmtool.wbdvmtool .date-month,
.wbdvmtool.wbdvmtool .date-year {
  padding-left: var(--textcontrols-padleft-small);
  padding-right: var(--textcontrols-padright-small);
}