.wbdvmtool-reminderpopup {
  display: none;
}

dialog .reminderpopup {
  display: block;
}

.wbdvmtool-reminder__datesettings,
.wbdvmtool-reminder__calendartype,
.wbdvmtool-reminder__buttonbar {
  margin-top: 20px;
}

.wbdvmtool-reminder__button {
  margin-top: 25px;
}

/*
wh-popup-chrome -wh-popup-chrome -wh-popup-theme-white wh-popup-theme-white theme-white animin-shove animout-shove wbdvmtool-reminderpopup wbdvmtool-popup
.wh-popup-chrome.wbdvmtool-reminderpopup
*/
.wbdvmtool-reminderpopup.wbdvmtool-popup.wbdvmtool-popup {
  padding: var(--tool-results-panel-padding-v) var(--tool-results-panel-padding-h);
}

/* when used inline instead (for mobile) instead of popup */
.measurevariant #wbdvmtool-reminderpopup {
  background-color: #F0F0F0;
  padding: 10px;
  margin: 0 20px 20px 100px;
}

body.mobile .measurevariant #wbdvmtool-reminderpopup {
  background-color: #F0F0F0;
  padding: 10px;
  margin: 0 20px 20px 15px;
}

/* use .wbdvmtool-reminderpopup class instead of ID
   so partner CSS can easily override our CSS (due to specificy rules)
*/
.wbdvmtool-reminderpopup .wbdvmtool-reminder__header {
  color: var(--tool-heading-color);
  font: var(--tool-results-h2);
  margin-bottom: 10px;
}

.wbdvmtool-reminder__preview {
  background-color: #F0F0F0;
  padding: var(--tool-results-panel-padding-v) var(--tool-results-panel-padding-h);
  margin-bottom: 15px;
}

.wbdvmtool-reminder__title {
  font-weight: bold;
  margin: 0 0 10px 0;
}

.wbdvmtool-reminder__datesettings label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wbdvmtool-reminder__datesettings label input {
  width: calc(100% - 60px);
}

.wbdvmtool-reminder__calendartype label {
  display: flex;
  align-items: center;
}

.wbdvmtool-reminder__calendartype input {
  margin-right: 15px;
}

.wbdvmtool-reminder__calendartype label + label {
  margin-top: 10px;
}

.wbdvmtool-reminderpopup .preview,
.wbdvmtool-reminderpopup .datesettings,
.wbdvmtool-reminderpopup .calendarlinks,
.wbdvmtool-reminderpopup hr {
  margin-bottom: 25px;
}

.wbdvmtool-reminderpopup .reminder-title {
  /* reset other .title stuff */
  font: inherit;
  padding: 0;
}

.wbdvmtool-reminderpopup .reminder-description {
  margin: 0;
}

.wbdvmtool-reminderpopup .reminder-link {
  display: inline-block;
  margin: 10px 0 0 0;
}

body.mobile .wbdvmtool-reminderpopup .datesettings label {
  display: block;
  margin-left: 0;
}

.wbdvmtool-reminderpopup .datesettings .wh-datepicker {
  width: 160px;
}

.wbdvmtool-reminderpopup .calendarlinks label {
  display: block;
}

.wbdvmtool-reminderpopup .calendarlinks label + label {
  margin-top: 10px;
}

.measurevariant .wbdvmtool-reminderpopup hr {
  background-color: #444444;
}

.wbdvmtool-reminderpopup hr {
  height: 1px;
  background-color: #daf0e4;
  border: 0;
}

.wbdvmtool-reminderpopup .buttons {
  overflow: hidden; /* grow to fit buttons */
}

.wbdvmtool-reminderpopup .buttons .nextbutton.disabled {
  opacity: 0.5;
}