/*
@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
.wbdvmtool__question__contents {
  position: relative;
}

.wbdvmtool__moreinfobutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: var(--tool-panel-whitespace-top);
  right: var(--tool-question-sidepadding);
  padding: 5px;
  margin-right: -5px;
  margin-top: -5px;
  cursor: pointer;
}

.wbdvmtool__moreinfobutton::before {
  display: block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f059";
  font-size: 26px;
  color: var(--tool-themecolor);
  margin-left: auto;
}
@media (max-width: 750px) {
  .wbdvmtool__moreinfobutton::before {
    font-size: 22px;
  }
}

.wbdvmtool__moreinfo {
  display: none;
  position: absolute;
  top: calc(var(--tool-panel-whitespace-top) - var(--tool-moreinfo-padding-v));
  right: calc(var(--tool-question-sidepadding) - var(--tool-moreinfo-padding-h));
  z-index: 1;
  min-width: 50%;
  max-width: 100%;
  width: clamp(50%, 400px, 100%);
  padding: 15px;
  padding: var(--tool-moreinfo-padding-v) var(--tool-moreinfo-padding-h);
  border: 2px solid #28A532;
  border-radius: 11px;
  background-color: #F1F9F1;
  box-shadow: 0 1px 7px 3px rgba(0, 0, 0, 0.1);
}

.wbdvmtool__question--showmoreinfo .wbdvmtool__moreinfo {
  display: block;
}

.wbdvmtool__moreinfo__closebutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  width: min-content;
  margin-left: auto;
  font: 16px var(--tool-headingfontfamily);
  color: var(--tool-themecolor-bright);
  cursor: pointer;
}

.wbdvmtool__moreinfo__closebutton::after {
  margin-left: 6px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f057";
  font-size: 22px;
}

.wbdvmtool__moreinfo__body {
  margin-top: 18px;
}

.wbdvmtool__moreinfo__body > p.normal,
.wbdvmtool__moreinfo__body > ol.ordered,
.wbdvmtool__moreinfo__body > ul.unordered {
  font: var(--tool-tooltip-font);
  color: #333333;
  padding: 0;
}

.wbdvmtool__moreinfo__body > *:first-child {
  margin-top: 0;
}