/*
@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
#conceptmode {
  display: none;
  text-align: center;
  margin-bottom: 5px;
  padding: 15px 0;
  font-size: 15px;
  color: #AA0000;
}

body.conceptmode #conceptmode {
  display: block;
}

html.site--wig .wbdvmtool__wiglink {
  display: none;
}

.html-debug .wbdvmtool__page {
  opacity: 0.5;
}
.html-debug .wbdvmtool__page--visible {
  opacity: 1;
}

#formcontainer {
  display: flex;
  flex-direction: column;
}

.wbdvmtool__questionsgroup:not(.wbdvmtool__pagegroup--active) {
  display: none;
}
html.dompack--debug-show_all_pages .wbdvmtool__questionsgroup:not(.wbdvmtool__pagegroup--active) {
  display: block;
}

.wbdvmtool__page:not(.wbdvmtool__page--visible) {
  display: none;
}
html.dompack--debug-show_all_pages .wbdvmtool__page:not(.wbdvmtool__page--visible) {
  display: block;
}

.wbdvmtool a {
  color: var(--tool-anchor-color);
}

.wbdvmtool-rtdcontent h2 {
  color: var(--tool-heading-color);
  font: var(--tool-results-h2);
  margin-bottom: 10px;
}

.wbdvmtool-rtdcontent > p.normal,
.wbdvmtool-rtdcontent > ol.ordered,
.wbdvmtool-rtdcontent > ul.unordered {
  font: var(--tool-text-font);
  color: #333333;
  padding: 0;
}

.wbdvmtool--showingresults .wbdvmtool__progressholder {
  display: none;
}

.wbdvmtool__question__hints ~ .answers {
  margin-top: 15px;
}

.wbdvmtool__question__error ~ .answers {
  margin-top: 15px;
}

.wbdvmtool__results__sharetool {
  margin-top: 30px;
}

.wbdvmtool__questionsgroup__title {
  outline: 1px solid #00A;
  background-color: rgba(0, 0, 255, 0.25);
}

.wbdvmtool__questionsgroup__title::before {
  content: "Group:";
}

.wbdvmtool__wiglink {
  display: table; /* or use block + width: min-content; */
  margin-top: 20px;
  margin-left: auto;
  width: 100%;
  max-width: 200px;
  max-height: 100px;
}

.wbdvmtool__wiglink img {
  width: 100%;
}

#results.effecttool__results {
  position: relative;
  left: auto;
  overflow: visible;
}

/*
WBDVM progressbar:

<div class="wbdvmtool__progressholder">
  <div class="wbdvmtool__progressholder__text" aria-live="polite">Nog maximaal 5 vragen</div>
  <div id="questionsleftbar">
    <div id="questionsleftprogress" style="width: 28.5714%;">
    </div>
  </div>
</div>

ToolPlatform Progressbar

<div id="questionscounter" class="wbdvmtool__progressholder">
  <div id="questionsleft" class="wbdvmtool__progressholder__text"></div>
  <div id="questionsleftbar" class="wbdvmtool__progressholder__bar"><div id="questionsleftprogress" class="wbdvmtool__progressholder__bar__knob"></div></div>
</div>
*/
/*
Bar with gradient and track has it's own border:

    .wbdvmtool__progressholder__text
    {
      display: none;
    }

    //.wbdvmtool__progressholder
    .wbdvmtool__progressholder__bar
    {
      max-width: none; // override both the WBDVM and Toolplatform styling

      box-sizing: border-box;
      height: 24px;
      padding: 1px 1px;

      border: 2px solid #13A538;
      border-radius: 10px;
      background-color: #F2F2F2;

      margin-bottom: 24px;
    }

    .wbdvmtool__progressholder__bar__inner
    {
      // width: 173px;
      padding-right: 5%; // to start off with a little bar
      height: 18px;
      border-radius: 9px;
      background: linear-gradient(180deg, #51D161 0%, #0B923E 100%);
    }
*/
.wbdvmtool__progressholder__text {
  height: 0;
  overflow: hidden;
}

.wbdvmtool__progressholder__bar {
  max-width: none;
  height: 18px;
  border-radius: 10px;
  background-color: #F2F2F2;
  background-color: var(--tool-themecolor-faded);
  margin-bottom: 24px;
}

.wbdvmtool__progressholder__bar__inner {
  padding-right: 5%;
  height: 100%;
  border-radius: 9px;
  background-color: var(--tool-themecolor-bright);
}

.wbdvmtool__question__contents {
  border: 2px solid var(--tool-border-color);
  border-radius: var(--tool-border-radius);
  padding: 25px 0 0 0;
  padding: var(--tool-panel-whitespace-top) 0 0 0;
}

html:not(.site--wig) .wbdvmtool__results .wbdvmtool__results__header h2 {
  font: bold 26px/31px var(--tool-headingfontfamily);
}

html:not(.site--wig) .wbdvmtool__results {
  border: 2px solid var(--tool-border-color);
  border-radius: var(--tool-border-radius);
  padding: 25px 0 0 0;
  padding: var(--tool-panel-whitespace-top) 0 0 0;
  padding: 25px var(--tool-question-sidepadding);
}

.wbdvmtool__question__title,
.wbdvmtool__questiontext,
.wbdvmtool__question__question,
.wbdvmtool__questioncontainer,
.wbdvmtool__navbuttons buttons {
  padding: 0 var(--tool-question-sidepadding);
}

.wbdvmtool__questioncontainer {
  margin-top: 25px;
}

.wbdvmtool__questiontext > *:first-child {
  margin-top: 0;
}

.wbdvmtool__navbuttons {
  margin-top: 40px;
  display: flex;
  background-color: var(--tool-question-buttonbar-background);
  padding: var(--tool-question-buttonbar-padding-v) var(--tool-question-sidepadding);
}

.wbdvmtool__navbuttons__spacer {
  flex: 1 0 25px;
}

.wbdvmtool__questioncontainer {
  font: var(--tool-questiontext-font);
}

.wbdvmtool__question__title,
.wbdvmtool__questiontext.wbdvmtool__questiontext > h2 {
  color: var(--tool-questionheading-color);
  font: var(--tool-questionheading-font);
  margin-bottom: 17px;
}

.wbdvmtool__question__question {
  font: var(--tool-questiontext-font);
}

.wbdvmtool__question--withmoreinfo .wbdvmtool__question__title {
  padding-right: 65px;
}

.wbdvmtool__question__question > *:last-child {
  margin-bottom: 0;
}

.wbdvmtool__questiontext,
.wbdvmtool__question__question {
  color: var(--tool-questiontext-color);
}

@media (max-width: 750px) {
  .wbdvmtool__questiontext,
  .wbdvmtool__question__question {
    font: var(--tool-questionheading-font-mobile);
  }
}
.wbdvmtool__question__error {
  margin-bottom: 10px;
  color: #AA0000;
  display: none;
}

.wbdvmtool__question__error .wbdvmtool__question__error {
  display: block;
}

input[type=number].date-day {
  width: 68px;
  margin-right: 12px;
}

input[type=number].date-month {
  width: 68px;
  margin-right: 12px;
}

input[type=number].date-year {
  width: 80px;
}

.wbdvmtool__radiolist {
  color: var(--tool-optionlabel-textcolor);
}

.wbdvmtool__radiolist__answer,
.wbdvmtool__checkboxlist__answer {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  padding: 7px 0;
}

.wbdvmtool__radiolist__answer + .wbdvmtool__radiolist__answer,
.wbdvmtool__checkboxlist__answer + .wbdvmtool__checkboxlist__answer {
  margin-top: 0;
}

.wbdvmtool__questioncontainer input[type=checkbox],
.wbdvmtool__questioncontainer input[type=radio] {
  margin: 0 18px 0 0;
}

.wbdvmtool button {
  cursor: pointer;
}

.wbdvmtool__checkboxlist__answer input[type=checkbox]:focus,
.wbdvmtool__radiolist__answer input[type=radio]:focus {
  outline-offset: 0;
  outline: 1px solid var(--formcontrol-checkradio-checked-bordercolor);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
}

.wbdvmtool__button {
  outline-offset: 4px;
}

.wbdvmtool__prevbutton,
.wbdvmtool__nextbutton,
.wbdvmtool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wbdvmtool__prevbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 44px;
  background-color: #FFFFFF;
  border: 2px solid var(--tool-button-color);
  border-radius: 5px;
  text-align: left;
  color: var(--tool-button-color);
  font: var(--tool-button-font-desktop);
  font-weight: 400;
  padding-right: 19px;
  padding-left: 14px;
}
@media (max-width: 750px) {
  .wbdvmtool__prevbutton {
    font: var(--tool-button-font-mobile);
  }
}
.wbdvmtool__prevbutton[disabled] {
  border-color: #A8A8A8;
  color: #A8A8A8;
}

.wbdvmtool__prevbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f053";
  font-size: 18px;
  margin-right: var(--tool-button-icon-margin-prev);
  width: auto;
  height: auto;
}

.wbdvmtool__prevbutton[disabled] {
  display: none;
}

.wbdvmtool__nextbutton,
.wbdvmtool__reminder__download.wbdvmtool__button,
.wbdvmtool-reminderbutton,
.wbdvmtool-reminder__button,
.wbdvmtool__givenanswers__restarttool {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 44px;
  background-color: var(--tool-button-color);
  border-radius: 5px;
  text-align: left;
  color: #FFFFFF;
  font: var(--tool-button-font-desktop);
  padding-left: 19px;
  padding-right: 14px;
  cursor: pointer;
}
@media (max-width: 750px) {
  .wbdvmtool__nextbutton,
  .wbdvmtool__reminder__download.wbdvmtool__button,
  .wbdvmtool-reminderbutton,
  .wbdvmtool-reminder__button,
  .wbdvmtool__givenanswers__restarttool {
    font: var(--tool-button-font-mobile);
  }
}
.wbdvmtool__nextbutton[disabled],
.wbdvmtool__reminder__download.wbdvmtool__button[disabled],
.wbdvmtool-reminderbutton[disabled],
.wbdvmtool-reminder__button[disabled],
.wbdvmtool__givenanswers__restarttool[disabled] {
  background-color: #A8A8A8;
}

a.wbdvmtool__reminder__download.wbdvmtool__button {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.wbdvmtool__nextbutton::before {
  display: none;
}

.wbdvmtool__nextbutton::after,
.wbdvmtool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 18px;
  margin-left: var(--tool-button-icon-margin-next);
}

.wbdvmtool__givenanswers__restarttool {
  padding-right: 30px;
}

.wbdvmtool__givenanswers__restarttool::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f01e";
  font-size: 16px;
  margin-right: 20px;
}