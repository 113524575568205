/*
@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
.wbdvmtool input::placeholder {
  opacity: 1;
  color: var(--textcontrols_placeholdercolor);
}
.wbdvmtool :disabled input::placeholder, .wbdvmtool [data-wh-form-disabled] input::placeholder {
  opacity: 1;
  color: var(--textcontrols_disabled_placeholder);
}
.wbdvmtool input[type=text],
.wbdvmtool input[type=url],
.wbdvmtool input[type=email],
.wbdvmtool input[type=password],
.wbdvmtool input[type=number],
.wbdvmtool input[type=date],
.wbdvmtool input[type=time],
.wbdvmtool input[type=search],
.wbdvmtool .wh-form__dateinputgroup,
.wbdvmtool .wh-form__timeinputgroup,
.wbdvmtool textarea,
.wbdvmtool select:not(.wh-rtd__toolbarstyle),
.wbdvmtool .multiselect__panel__trigger {
  padding-left: var(--textcontrols_padleft);
  padding-right: var(--textcontrols_padright);
  font: var(--textcontrols_font);
  height: var(--controls_height);
  border: 1px solid var(--controls_outline_color);
  border-radius: var(--controls_radius);
  background-color: #FFFFFF;
  color: var(--textcontrols_textcolor);
}
@media (max-width: 480px) {
  .wbdvmtool input[type=text],
  .wbdvmtool input[type=url],
  .wbdvmtool input[type=email],
  .wbdvmtool input[type=password],
  .wbdvmtool input[type=number],
  .wbdvmtool input[type=date],
  .wbdvmtool input[type=time],
  .wbdvmtool input[type=search],
  .wbdvmtool .wh-form__dateinputgroup,
  .wbdvmtool .wh-form__timeinputgroup,
  .wbdvmtool textarea,
  .wbdvmtool select:not(.wh-rtd__toolbarstyle),
  .wbdvmtool .multiselect__panel__trigger {
    padding-left: var(--textcontrols_padleft-mobile);
    padding-right: var(--textcontrols_padright-mobile);
  }
}
@media (max-width: 480px) {
  .wbdvmtool input[type=text],
  .wbdvmtool input[type=url],
  .wbdvmtool input[type=email],
  .wbdvmtool input[type=password],
  .wbdvmtool input[type=number],
  .wbdvmtool input[type=date],
  .wbdvmtool input[type=time],
  .wbdvmtool input[type=search],
  .wbdvmtool .wh-form__dateinputgroup,
  .wbdvmtool .wh-form__timeinputgroup,
  .wbdvmtool textarea,
  .wbdvmtool select:not(.wh-rtd__toolbarstyle),
  .wbdvmtool .multiselect__panel__trigger {
    font: var(--textcontrols_font_mobile);
    height: var(--controls_height_mobile);
  }
}
.wbdvmtool input[type=text]:focus,
.wbdvmtool input[type=url]:focus,
.wbdvmtool input[type=email]:focus,
.wbdvmtool input[type=password]:focus,
.wbdvmtool input[type=number]:focus,
.wbdvmtool input[type=date]:focus,
.wbdvmtool input[type=time]:focus,
.wbdvmtool input[type=search]:focus,
.wbdvmtool .wh-form__dateinputgroup:focus,
.wbdvmtool .wh-form__timeinputgroup:focus,
.wbdvmtool textarea:focus,
.wbdvmtool select:not(.wh-rtd__toolbarstyle):focus,
.wbdvmtool .multiselect__panel__trigger:focus {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
.wbdvmtool input[type=text]:disabled, .wbdvmtool input[type=text][data-wh-form-disabled],
.wbdvmtool input[type=url]:disabled,
.wbdvmtool input[type=url][data-wh-form-disabled],
.wbdvmtool input[type=email]:disabled,
.wbdvmtool input[type=email][data-wh-form-disabled],
.wbdvmtool input[type=password]:disabled,
.wbdvmtool input[type=password][data-wh-form-disabled],
.wbdvmtool input[type=number]:disabled,
.wbdvmtool input[type=number][data-wh-form-disabled],
.wbdvmtool input[type=date]:disabled,
.wbdvmtool input[type=date][data-wh-form-disabled],
.wbdvmtool input[type=time]:disabled,
.wbdvmtool input[type=time][data-wh-form-disabled],
.wbdvmtool input[type=search]:disabled,
.wbdvmtool input[type=search][data-wh-form-disabled],
.wbdvmtool .wh-form__dateinputgroup:disabled,
.wbdvmtool .wh-form__dateinputgroup[data-wh-form-disabled],
.wbdvmtool .wh-form__timeinputgroup:disabled,
.wbdvmtool .wh-form__timeinputgroup[data-wh-form-disabled],
.wbdvmtool textarea:disabled,
.wbdvmtool textarea[data-wh-form-disabled],
.wbdvmtool select:not(.wh-rtd__toolbarstyle):disabled,
.wbdvmtool select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wbdvmtool .multiselect__panel__trigger:disabled,
.wbdvmtool .multiselect__panel__trigger[data-wh-form-disabled] {
  color: var(--textcontrols_disabled_textcolor);
  border-color: var(--textcontrols_disabled_bordercolor);
  background-color: var(--textcontrols_disabled_bgcolor);
  box-shadow: none;
}
.wbdvmtool textarea {
  flex: 1 0 0px;
  padding-right: var(--textcontrols_padleft);
}
.wbdvmtool .wh-form__imgedit {
  border: 1px solid var(--controls_outline_color);
  border-radius: var(--controls_radius);
  background-color: #FFFFFF;
  color: var(--textcontrols_textcolor);
}
.wbdvmtool .wh-form__imgedit:focus {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
.wbdvmtool .wh-form__imgedit:disabled, .wbdvmtool .wh-form__imgedit[data-wh-form-disabled] {
  color: var(--textcontrols_disabled_textcolor);
  border-color: var(--textcontrols_disabled_bordercolor);
  background-color: var(--textcontrols_disabled_bgcolor);
  box-shadow: none;
}
.wbdvmtool .wh-form__rtd:focus-within {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
.wbdvmtool .wh-form__dateinputgroup:focus-within,
.wbdvmtool .wh-form__timeinputgroup:focus-within {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
.wbdvmtool .wh-form__dateinputgroup__line.wh-form__dateinputgroup__line input:focus,
.wbdvmtool .wh-form__timeinputgroup__line.wh-form__timeinputgroup__line input:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: #F0F0F0;
}

.wbdvmtool input[type=text],
.wbdvmtool input[type=url],
.wbdvmtool input[type=email],
.wbdvmtool input[type=password],
.wbdvmtool input[type=number],
.wbdvmtool input[type=date],
.wbdvmtool input[type=time],
.wbdvmtool input[type=search],
.wbdvmtool textarea {
  border-color: var(--tool-themecolor); /* - WIG Green-AA */
}

/****************/